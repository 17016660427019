import React from 'react';
import './css/NetworkWarning.css';
import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { NO_NETWORK } from '../../api/mintApi';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export class NetworkWarning extends React.Component {

  render() {
    const theme = createMuiTheme({
      typography: {
        useNextVariants: true,
      }
    });

    let icon = "";
    let networkMessage = "";
    let descriptionMessage = "";
    let walletLink = "";

    if (this.props.network === "morden") {
      icon = <FontAwesomeIcon size="2x" className="fa_warning_icon" icon={faExclamationTriangle} />;
      networkMessage = "正在使用 Morden 测试网络！";
      descriptionMessage = "你的代币合约将被部署到 Morden 测试网络。 " +
        "如果希望部署到以太坊主网，请切换到主网后刷新页面。";
    } else if (this.props.network === "ropsten") {
      icon = <FontAwesomeIcon size="2x" className="fa_warning_icon" icon={faExclamationTriangle} />;
      networkMessage = "正在使用 Ropsten 测试网络！";
      descriptionMessage = "你的代币合约将被部署到 Ropsten 测试网络。" +
        "如果希望部署到以太坊主网，请切换到主网后刷新页面。";
    } else if (this.props.network === "private") {
      icon = <FontAwesomeIcon size="2x" className="fa_warning_icon" icon={faExclamationTriangle} />;
      networkMessage = "正在使用私有测试网络！";
      descriptionMessage = "你的代币合约将被部署到私有测试网络。" +
        "如果希望部署到以太坊主网，请切换到主网后刷新页面。";
    } else if (this.props.network === NO_NETWORK) {
      icon = <FontAwesomeIcon size="2x" className="fa_error_icon" icon={faTimesCircle} />;
      networkMessage = "没有检测到以太坊钱包！";
      descriptionMessage = "我们推荐使用 ";
      walletLink = this.props.isMobileDevice ?
        <a href="https://wallet.coinbase.com/" rel="noopener noreferrer" target="_blank"> Coinbase手机钱包 ！</a> :
        <a href="https://metamask.io/" rel="noopener noreferrer" target="_blank"> Metamask ！</a>;
    } else {
      icon = <FontAwesomeIcon size="2x" className="fa_warning_icon" icon={faExclamationTriangle} />;
      networkMessage = "当前使用的不是以太坊主网！";
      descriptionMessage = "你的代币合约将不会部署到以太坊主网。" +
        "如果希望部署到以太坊主网，请切换到主网后刷新页面。";
    }

    return (
      <Card className="card">
        <CardHeader
          title="警告！"
          classes={{
            root: "card_header",
            title: "card_header_text"
          }}
          avatar={icon}
        />
        <CardContent
          classes={{
            root: "card_content"
          }}
        >
          <MuiThemeProvider theme={theme}>
            <Typography
              align="center"
              variant="h6"
            >
              {networkMessage}
            </Typography>
            <Typography
              align="left"
              variant="body1"
            >
              {descriptionMessage} {this.props.network === NO_NETWORK && walletLink}
            </Typography>
            {this.props.network === NO_NETWORK &&
              <Typography
                align="left"
                variant="body1"
              >
                Read about other wallet options at our <a
                  href="https://tokenmint.io/blog/web-3-enabled-ethereum-wallets-and-browsers.html"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Ethereum wallets
                </a> blog post.
              </Typography>
            }
          </MuiThemeProvider>
        </CardContent>
      </Card>
    );
  }
}

NetworkWarning.propTypes = {
  network: PropTypes.string.isRequired,
  isMobileDevice: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    network: state.network,
    isMobileDevice: state.isMobileDevice
  };
}

export default connect(mapStateToProps)(NetworkWarning);
